import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import queryString from 'query-string';
import { gql } from 'apollo-boost';

const usePreview = search => {
    const parsed = queryString.parse(search);
    const { revision, nonce } = parsed;

    // "nonce": "53a4b5132a",
    // "parent_id": "0",
    // "revision_id": "1229",
    // "status": "draft",
    // "type": "post"

    const id = revision;

    const PREVIEW_QUERY = gql`
        query getPreview($id: ID!) {
            post(id: $id, idType: DATABASE_ID) {
                title
                revisions {
                    nodes {
                        id
                        title
                        content
                    }
                }
            }
        }
    `;

    const { loading, error, data } = useQuery(PREVIEW_QUERY, {
        variables: { id, nonce },
    });

    return {
        loading,
        error,
        data,
    };
};

export default usePreview;
