import React from 'react';

import queryString from 'query-string';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import usePreview from '../hooks/use-preview';

const Preview = ({ location }) => {
    const preview = usePreview(location.search);

    return (
        <>
            <Seo title="Preview" />
            <pre>{JSON.stringify(queryString.parse(location.search), null, 2)}</pre>
            <pre>{JSON.stringify(preview, null, 2)}</pre>
        </>
    );
};

export default Preview;
